const ENV_NAME = "uat";
const URL = "https://uat.yoinves.id/";
const URL_CORP = "https://corporate-uat.yoinves.id";
const FLAG_TEXT = "UAT VERSION";

export default {
  ENV_NAME,
  URL,
  URL_CORP,
  FLAG_TEXT,
};
